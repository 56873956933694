import React from 'react'
import { AppLocale } from '@hrk/types'
import { SearchInputWrapper } from '../../components/search/searchInputWrapper'
import { LayoutWrapper } from '../../components/layout/layoutWrapper'
import { DownloadLink } from '@hrk/types'
import { useNavigationItems } from '../../hooks/useNavigationItems'
import { ArticleJsonLd } from 'gatsby-plugin-next-seo'
import { SiteMetadata, useSiteMetadata } from '../../hooks/useSiteConfig'
import { Seo } from '../../components/seo/Seo'
import { useTranslations } from '../../hooks/useTranslations'
import { ContentPageContext } from '../../@types/PageContext.type'
import slugify from 'slugify'

interface IContentPageTemplateProps {
  pageContext: ContentPageContext
}

const ContentPageTemplate = (props: IContentPageTemplateProps): JSX.Element => {
  const { collectBreadCrumbs } = useNavigationItems()
  const currentLocale = props.pageContext.locale as AppLocale
  const site: SiteMetadata = useSiteMetadata(currentLocale)
  const currentSlug = props.pageContext.slug
  const currentTitle = props.pageContext.title
  const currentDescription = props.pageContext.description
  const otherLocales =
    props.pageContext.localizations?.map((l) => ({
      locale: l?.locale as AppLocale,
      slug: l?.slug as string,
    })) || []

  const { download: downloadTranslations } = useTranslations()

  const contentItems = props.pageContext.json ?? []
  const searchWrapper = (props) => <SearchInputWrapper {...props} />

  const currentPagePath = collectBreadCrumbs({ ...props.pageContext, type: 'EditorialPages' })

  const seoTitle = currentTitle ?? props.pageContext.siteConfig.defaultTitle
  const seoDescription = currentDescription ?? props.pageContext.siteConfig.defaultDescription
  const seoCover = props.pageContext.siteConfig.defaultCover
  const pageUrl = `${site.metadata.siteUrl}${currentSlug.endsWith('/') ? currentSlug : currentSlug + '/'}`

  const currentPdfUrl: DownloadLink | undefined = props.pageContext.pdfUrl
    ? {
        url: props.pageContext.pdfUrl,
        target: '_blank',
        title: downloadTranslations.downloadLabel,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        downloadFilename: currentTitle ? `${slugify(currentTitle)}.pdf` : props.pageContext.pdfUrl.split('/').pop()!,
      }
    : undefined

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} slug={currentSlug} locale={currentLocale}></Seo>
      <ArticleJsonLd
        url={pageUrl}
        headline={seoTitle}
        images={[
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          seoCover.src!,
        ]}
        datePublished={site.build.buildTime}
        dateModified={site.build.buildTime}
        authorName={site.metadata.publisher}
        publisherName={site.metadata.publisher}
        publisherLogo={`${site.metadata.siteUrl}/HRKDFN8.gif`}
        description={seoDescription}
        overrides={{
          '@type': 'Article',
        }}
      />
      <LayoutWrapper
        currentLocale={currentLocale}
        currentSlug={currentSlug}
        currentTitle={seoTitle}
        otherLocales={otherLocales}
        contentItems={contentItems}
        searchWrapper={searchWrapper}
        currentPath={currentPagePath}
        currentPdfLink={currentPdfUrl}
      />
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return (
    <link
      rel="canonical"
      href={`${site.metadata.siteUrl}${pageContext.slug.endsWith('/') ? pageContext.slug : pageContext.slug + '/'}`}
    />
  )
}

export default ContentPageTemplate
